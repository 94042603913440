import { ComponentProps } from 'react';
import { Document } from '@contentful/rich-text-types';
import type MainHero from '../src/modules/MainHero';
import type Content from '../src/modules/Content';
import type SimpleQuote from '../src/modules/SimpleQuote';
import type Cards from '../src/modules/Cards';
import type SubpageHeader from '../src/modules/SubpageHeader';
import type Heading from '../src/modules/Heading';
import type CustomerLogos from '../src/modules/CustomerLogos';
import type HubspotForm from '../src/modules/HubspotForm';
import type LargeQuote from '../src/modules/LargeQuote';
import type PageBottomCTA from '../src/modules/PageBottomCTA';
import type LatestUpdates from '../src/modules/LatestUpdates';
import type FAQ from '../src/modules/FAQ';
import type PageLinks from '../src/modules/PageLinks';
import type PricingTable from '../src/modules/PricingTable';
import type GreenHouse from '../src/modules/GreenHouse';
import type ComparisonMatrix from '../src/modules/ComparisonMatrix';
import type FeatureCards from '../src/modules/FeatureCards';
import type { ContentfulAsset } from '../src/helpers/RichTextHelpers';
import { Theme } from '../styles/themes';

export type Module = ComponentProps<typeof Content>
  | ComponentProps<typeof Heading>
  | ComponentProps<typeof SimpleQuote>
  | ComponentProps<typeof Cards>
  | ComponentProps<typeof CustomerLogos>
  | ComponentProps<typeof MainHero>
  | ComponentProps<typeof HubspotForm>
  | ComponentProps<typeof FAQ>
  | ComponentProps<typeof LargeQuote>
  | ComponentProps<typeof LatestUpdates>
  | ComponentProps<typeof PageBottomCTA>
  | ComponentProps<typeof SubpageHeader>
  | ComponentProps<typeof PricingTable>
  | ComponentProps<typeof GreenHouse>
  | ComponentProps<typeof PageLinks>
  | ComponentProps<typeof FeatureCards>
  | ComponentProps<typeof ComparisonMatrix>;

export type WithId<T> = T & { sys: { id: string } };
export type WithTypename<T extends {__typename?: string}> = T & { __typename: T['__typename'] };

export type CFImage = {
  url: string;
  description: string;
  width: number;
  title: string;
  height: number;
  __typename?: 'Asset';
  contentType: string;
};

export type CFLink = {
  title: string;
  url?: string;
  page?: {
    __typename?: 'BlogPost' | 'Page';
    slug: string;
  };
  newTab: boolean;
  image?: CFImage;
  wistiaVideoId?: string;
  __typename?: 'AnyLink';
};
export type FullCFLink = WithTypename<CFLink>;

export type CFRichTextLinks = {
  entries: {block: ContentfulAsset[]};
  assets: {block: ContentfulAsset[]};
};

export type CFAsset = {
  url: string;
  title: string;
  description: string;
  contentType: string;
  __typename?: 'Asset';
}

export type CFButton = {
  title: string;
  label: string;
  url: string;
  __typename?: 'Button';
}

export type WistiaOEmbedData = {
  thumbnail_url: string;
  thumbnail_width: number;
  thumbnail_height: number;
  title: string;
}

export type ResourceLibraryItem = WithId<{
  title: string;
  type: string;
  image?: CFImage;
  description: string;
  linkedResource?: CFLink;
  assetResource?: CFAsset;
  wistiaVideoId?: string;
  wistiaData?: WistiaOEmbedData;
  thumbnailPosition?: string;
}>;

export type ResourceLibraryPage = {
  title: string;
  metaDescription: string;
  topModules: Module[];
  theme: Theme;
  ogImage?: CFImage;
  bottomModules: Module[];
}

export type ResourceResponse = {
  data: ResourceLibraryItem[],
  pages: number,
  totalResources: number
};

export type NewsItem = WithId<{
  title: string;
  slug?: string;
  metaDescription: string;
  type: 'news' | 'event' | 'press-release';
  image: CFImage;
  date: string;
  endDate?: string;
  location?: string;
  link?: CFLink;
  content: {
    json: Document;
    links: CFRichTextLinks;
  };
  backgroundImage: CFImage;
  bottomCtaModule: ComponentProps<typeof PageBottomCTA>;
}>;

export type EmbeddedVideo = {
  __typename?: 'WistiaVideo';
  videoId: string;
  type: 'Wistia' | 'YouTube';
}

export type Redirect = {from: string, to: string, permanent: boolean};

export const imageWithIdQuery = `{
  url,
  description,
  width,
  title,
  height,
  contentType,
  sys { id },
  __typename
}`;

export const imageQuery = '{ url, description, width, title, height, contentType, __typename }';

export const buttonQuery = '{ title, label, url, __typename }';

export const innerAnyLinkQuery = `
  ... on AnyLink {
    title
    url
    newTab
    page {
      ... on Page {
        slug
        __typename
      }

      ... on BlogPost {
        slug
        __typename
      }
    }
    wistiaVideoId
    __typename
  }
`;

export const linkQuery = `
{ ${innerAnyLinkQuery} }`;
