import React, { Dispatch, SetStateAction } from 'react';
import styled, { css } from 'styled-components';
import Button from './Button';

export interface PricingSelectorProps {
  setShowMonthly: Dispatch<SetStateAction<boolean>>;
  showMonthly: boolean;
  shadow?: boolean;
  small?: boolean;
}

const Wrapper = styled.div<Pick<PricingSelectorProps, 'small' | 'shadow'>>`
  display: inline-flex;
  justify-content: center;
  padding: 4px;
  background-color: white;
  border: 1px solid var(--color-charcoal-v10);
  border-radius: 12px;
  margin-bottom: 24px;

  > button {
    padding: 12px 36px;

    ${(props) => props.small && css`
        padding: 10px 32px;
    `}

    &.secondary {
      border-color: transparent;
      color: var(--color-charcoal-v50);

      &:hover {
        background-color: unset;
      }
    }
  }
  
  ${(props) => props.shadow && css`
    box-shadow: ${props.theme.shadows.hard};
  `}
`;

const PricingSelector: React.FC<PricingSelectorProps> = ({
  setShowMonthly, showMonthly, ...props
}) => (
  <Wrapper {...props}>
    <Button onClick={() => void setShowMonthly(true)} secondary={!showMonthly}>
      Monthly
    </Button>
    <Button onClick={() => void setShowMonthly(false)} secondary={showMonthly}>
      Annually
    </Button>
  </Wrapper>
);

export default PricingSelector;
