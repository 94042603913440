import { gql } from '@apollo/client';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';
import Image from 'next/image';
import styled, { css } from 'styled-components';
import { FC, ReactNode } from 'react';
import {
  CFButton, CFImage, CFLink, WithId,
} from '../../lib/types';
import { normalizeThemeClassName, Theme } from '../../styles/themes';
import Button from '../components/Button';
import { RichText } from '../helpers/RichTextHelpers';
import { CardProps } from './Cards';
import { AutoLink } from '../components/Link';

export const FEATURE_CARDS_MODULE_MODULE_TYPE_ID = 'feature_cards_module';

type Props = WithId<{
  intunioModuleTypeId: typeof FEATURE_CARDS_MODULE_MODULE_TYPE_ID;
  title: string
  primaryButton: CFButton
  secondaryButton: CFButton
  backgroundImage: CFImage
  rightAligned: boolean
  theme: Theme
  featureCards: CardProps[]
  anchorId?: string
}>;

export const featureCardsModule = gql`
fragment featureCardsModule on FeatureCardsModule {
  sys { id }
  intunioModuleTypeId
  title
  primaryButton {...button}
  secondaryButton {...button}
  backgroundImage {...image}
  rightAligned
  theme
  featureCardsCollection(limit: 10) {
    items {
      sys { id }
      heading
      body {
        json
      }
      imagePosition
      image {...image}
      link {...link}
    }
  }
  anchorId
}`;
type ContainerProps = {
  backgroundImageUrl: string;
  rightAligned: boolean;
};

const Container = styled.div<ContainerProps>`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  gap: 48px;
  width: 100%;
  background: var(--theme-background);
  padding: 90px 64px;
  box-sizing: border-box;
  background-image: ${(props) => (props.backgroundImageUrl ? (`url(${props.backgroundImageUrl})`) : 'none')};
  background-repeat: no-repeat;
  background-position: ${(props) => (props.rightAligned ? 'right calc(50% - 700px) bottom -0px' : 'left calc(50% - 710px) bottom -0px')};

  @media (max-width: 1440px) {
    background-position: ${(props) => (props.rightAligned ? 'right -180px bottom -0px' : 'left -110px bottom -0px')};;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.large}) {
    gap: 32px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.medium}) {
    background-image: none;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.small}) {
    padding: 48px 24px;
  }
`;

const Heading = styled.h2`
  text-align: center;
  font-size: 32px;
  margin: 0;
  @media (max-width: ${(props) => props.theme.breakpoints.large}) {
    font-size: 28px;
  }
`;

type GridContainerProps = {
  rightAligned: boolean,
}

const CardGridContainer = styled.div<GridContainerProps>`
  display: grid;
  grid-template-areas: ${(props) => (props.rightAligned
    ? `'a b c'
       'd d c'`
    : `'c b a'
       'c d d'`)};
  gap: 24px;
  width: 100%;
  min-height: 440px;
  max-width: 1440px;

  @media (max-width: ${(props) => props.theme.breakpoints.large}) {
    grid-template-areas: ${(props) => (props.rightAligned
    ? `'d a'
       'd c'
       'b c'`
    : `'c a'
       'c d'
       'b d'`)};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.medium}) {
    grid-template-rows: minmax(auto, 1fr);
    grid-template-columns: 1fr;
    grid-template-areas: ${(props) => (props.rightAligned
    ? `'d'
       'a'
       'b'
       'c'`
    : `'c'
       'a'
       'b'
       'd'`)};
  }
`;

type CardBaseContainerProps = {
  gridArea: string;
  hasLink: boolean;
}

const CardBase = styled.div<CardBaseContainerProps>`
  display: flex;
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  border: 1px solid var(--theme-border-color);
  grid-area: ${(props) => (props.gridArea)};

  box-shadow: ${(props) => props.theme.shadows.hard};
  border-radius: 16px;
  overflow: hidden;
  color: var(--color-charcoal-v100);

  ${(props) => props.hasLink && css`
    cursor: pointer;
    transition: border-color 0.2s ease-in-out;
    &:hover {
      border-color: var(--theme-border-color-hover);
    }
  `}
`;

type FeatureCardProps = {
  link?: CFLink;
  children: ReactNode;
  cardType: typeof CardBase;
} & Pick<CardBaseContainerProps, 'gridArea'>;

const FeatureCard: FC<FeatureCardProps> = ({
  gridArea, link, children, cardType: Card,
}) => {
  const inner = (
    <Card
      hasLink={!!link}
      gridArea={gridArea}
    >
      {children}
    </Card>
  );
  return (
    link ? (
      <AutoLink
        style={{
          gridArea,
        }}
        {...link}
      >
        {inner}
      </AutoLink>
    ) : (
      inner
    )
  );
};
const CardWithImage = styled(CardBase)`
  display: flex;
  flex-direction: column;

  img {
    object-fit: cover;
    max-height: 60%;
    width: 100%;
    height: min-content;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.medium}) {
    height: auto;
  }
`;

const H3CustomMargin = styled.h3`
  margin: 0 0 12px 0;
  font-size: 21px;
  font-weight: 400;
`;

const CardHorizontalWithImage = styled(CardBase)`
  display: flex;
  flex-direction: row;

  img {
    flex: 1;
    object-fit: cover;
    height: 100%;
    max-width: 50%;

    @media (max-width: ${(props) => props.theme.breakpoints.large}) {
      object-fit: cover;
      max-width: unset;
      width: 100%;
      height: auto;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.large}) {
    flex-direction: column;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
`;

const CardContentContainer = styled.div`
  padding: 24px;
  box-sizing: border-box;
  .rich-text p:last-child {
    margin-bottom: 0;
  }
`;

type CardGridProps = {
  cards: Array<CardProps>
  rightAligned: boolean;
}

type CardContainerProps = {
  card: CardProps
}

const CardContent = ({ card }: CardContainerProps) => (
  <CardContentContainer>
    <H3CustomMargin>
      {card.heading}
    </H3CustomMargin>
    <RichText>
      {card.body
        ? documentToReactComponents(card.body.json as Document)
        : null}
    </RichText>
  </CardContentContainer>
);

const CardGrid = ({ cards, rightAligned }: CardGridProps) => {
  const cardsWithImage = [] as Array<CardProps>;
  const cardsWithNoImage = [] as Array<CardProps>;
  cards.forEach((card) => {
    if (card.image) {
      cardsWithImage.push(card);
    } else {
      cardsWithNoImage.push(card);
    }
  });
  let renderedMainCardWithImage = null;
  let img = cardsWithImage[0]?.image;
  if (cardsWithImage.length > 0) {
    renderedMainCardWithImage = (
      <FeatureCard link={cardsWithImage[0].link} cardType={CardWithImage} gridArea="c">
        {img && (
        <Image
          width={img.width}
          height={img.height}
          src={img.url}
          alt={cardsWithImage[0].heading || ''}
        />
        )}
        <CardContent card={cardsWithImage[0]} />
      </FeatureCard>
    );
    cardsWithImage.shift();
  }
  let renderedSecondaryCardWithImage = null;
  img = cardsWithImage[0]?.image;
  if (cardsWithImage.length > 0) {
    renderedSecondaryCardWithImage = (
      <FeatureCard cardType={CardHorizontalWithImage} link={cardsWithImage[0].link} gridArea="d">
        {img && (
        <Image
          width={img.width}
          height={img.height}
          src={img.url}
          alt={cardsWithImage[0].heading || ''}
        />
        )}
        <CardContent card={cardsWithImage[0]} />
      </FeatureCard>
    );
    cardsWithImage.shift();
  }
  // In case they add images to more cards we join the cards again.
  const joinedCards = cardsWithImage.concat(cardsWithNoImage);
  const gridAreas = ['a', 'b'];
  let renderedImagelessCards = [] as Array<JSX.Element>;
  if (joinedCards.length > 0) {
    renderedImagelessCards = joinedCards.map((card, i) => (
      <FeatureCard
        link={card.link}
        cardType={CardBase}
        gridArea={gridAreas[i]}
        key={card.sys.id}
      >
        <CardContent card={card} />
      </FeatureCard>
    ));
  }
  return (
    <CardGridContainer rightAligned={rightAligned}>
      {renderedImagelessCards}
      {renderedSecondaryCardWithImage}
      {renderedMainCardWithImage}
    </CardGridContainer>
  );
};

const FeatureCards = ({
  title, theme, featureCards, rightAligned, primaryButton, secondaryButton, backgroundImage,
  anchorId,
}: Props) => (
  <div className={normalizeThemeClassName(theme)} id={anchorId}>
    <Container backgroundImageUrl={backgroundImage?.url} rightAligned={rightAligned}>
      <Heading>
        {title}
      </Heading>
      <CardGrid cards={featureCards} rightAligned={rightAligned} />
      <ButtonContainer>
        {primaryButton && <Button href={primaryButton.url}>{primaryButton.label}</Button>}
        {secondaryButton
          && <Button secondary href={secondaryButton.url}>{secondaryButton.label}</Button>}
      </ButtonContainer>
    </Container>
  </div>
);

export default FeatureCards;
