/* eslint-disable import/prefer-default-export */
const greenhouseJobsUrl = 'https://boards-api.greenhouse.io/v1/boards/1223/jobs/';
const greenhouseJobsDepartments = 'https://boards-api.greenhouse.io/v1/boards/1223/departments/';

export type FullJobListing = {
  absolute_url: string
  data_compliance: [
    {
        type: string,
        requires_consent: boolean,
        retention_period: string
    }
  ],
  content: string,
  departments: Array<{
    child_ids: number,
    id: number,
    name: string,
    parent_id: number,
  }>
  id: number;
  internal_job_id: number
  location: {
    name: string
  },
  metadata: [
    {
        id: number,
        name: string,
        value: string,
        value_type: string,
    },
    {
        id: number,
        name: string,
        value: {
            unit: string,
            max_value: string,
            min_value: string
        },
        value_type: string
    },
    {
        id: number,
        name: String,
        value: {
            max_value: string,
            min_value: string
        },
        value_type: string
    }
  ],
  offices: Array<string>,
  requisition_id: string,
  title: string,
  updated_at: string
}

export type JobListing = {
  absolute_url: string
  data_compliance: [
      {
          type: string,
          requires_consent: boolean,
          retention_period: string
      }
  ],
  internal_job_id: number,
  location: {
      name: string
  },
  metadata: [
      {
          id: number,
          name: string,
          value: string,
          value_type: string,
      },
      {
          id: number,
          name: string,
          value: {
              unit: string,
              max_value: string,
              min_value: string
          },
          value_type: string
      },
      {
          id: number,
          name: String,
          value: {
              max_value: string,
              min_value: string
          },
          value_type: string
      }
  ],
  id: number,
  updated_at: string,
  requisition_id: string,
  title: string
};

export type Department = {
  id: string,
  jobs: Array<JobListing>
  name: string,
  child_ids: Array<string>
  parent_id: string
  };

type GreenHouseJobListingResponse = {
    jobs: Array<JobListing>,
    meta: {
      total: number,
    }
  }

  type GreenHouseDepartmentsResponse = {
    departments: Array<Department>
  }

const fetchListing = async (url: string) => {
  const res = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
  return res;
};

export const fetchDepartments = async () : Promise<GreenHouseDepartmentsResponse> => {
  const resDept = await fetchListing(greenhouseJobsDepartments);
  return await resDept.json() as GreenHouseDepartmentsResponse;
};

export const fetchJoblisting = async () : Promise<GreenHouseJobListingResponse> => {
  const res = await fetchListing(greenhouseJobsUrl);
  return await res.json() as GreenHouseJobListingResponse;
};

export const fetchJob = async (id: string) : Promise<FullJobListing> => {
  const res = await fetchListing(`${greenhouseJobsUrl}${id}`);
  return await res.json() as FullJobListing;
};
