import { gql } from '@apollo/client';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';
import { useRef, useState } from 'react';
import styled from 'styled-components';
import { WithId } from '../../lib/types';
import { Theme } from '../../styles/themes';
import H2 from '../components/H2';
import LargeText from '../components/LargeText';
import ModulePaddingWrapper from '../components/ModulePaddingWrapper';

export const FAQ_MODULE_TYPE_ID = 'faq_module';

type FAQItemProps = WithId<{
  question: string
  answer: {
    json: Document
  }
}>;

type Props = {
  intunioModuleTypeId: typeof FAQ_MODULE_TYPE_ID,
  theme?: Theme,
  heading?: string,
  faqItems: FAQItemProps[]
}

const Chevron = () => (
  <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L7 5.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L7.70711 7.70711C7.31658 8.09763 6.68342 8.09763 6.29289 7.70711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z" fill="currentColor" />
  </svg>
);

export const faqModule = gql`
fragment faqModule on FaqModule {
  intunioModuleTypeId
  heading
  faqItemsCollection(limit: 10) {
    items {
      sys {
        id
      }
      question
      answer {
        json
      }
    }
  }
}`;

const FAQItemHead = styled.button<{expanded: boolean}>`
  display: flex;
  appearance: none;
  margin: 0;
  text-align: start;
  background-color: transparent;
  border: none;
  width: 100%;
  font-size: inherit;
  font: inherit;
  cursor: pointer;
  padding: 24px;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  svg {
    transition: transform 0.2s;
    transform: rotate(${(props) => (props.expanded ? '180deg' : '0')});
    min-width: 14px;
  }
`;

const Answer = styled.div<{expanded: boolean}>`
  max-height: 0;
  overflow: hidden;
  padding: 0 24px;
  padding-bottom: ${(props) => (props.expanded ? '24px' : '0')};

  transition-duration: inherit;
  transition-property: max-height, padding;

  > :last-child {
    margin-bottom: 0;
  }

  > :first-child {
    margin-top: 0;
  }
`;

const FAQItemElement = styled.div`
  box-shadow: ${(props) => props.theme.shadows.hard};
  border-radius: 12px;
  border: 1px solid var(--theme-border-color);
  background-color: white;

  transition-duration: 0.2s;
  transition-property: border-color;

  &:hover {
    border-color: var(--theme-border-color-hover);
  }
`;

const FAQItems = styled.div`
  display: grid;
  max-width: 1000px;
  margin: 0 auto;
  gap: 24px;
`;

const FAQItem = ({ question, answer } : FAQItemProps) => {
  const [expanded, setExpanded] = useState(false);
  const [height, setHeight] = useState(0);
  const answerRef = useRef<HTMLDivElement>(null);

  const toggleExpanded = () => {
    const realExpanded = !expanded;
    setExpanded(realExpanded);
    if (answerRef.current) {
      if (realExpanded) {
        setHeight(answerRef.current.scrollHeight);
      } else {
        setHeight(0);
      }
    }
  };

  return (
    <FAQItemElement>
      <FAQItemHead
        onClick={toggleExpanded}
        expanded={expanded}
        aria-expanded={expanded}
      >
        <LargeText>{question}</LargeText>
        <Chevron />
      </FAQItemHead>
      {answer && (
        <Answer style={{ maxHeight: height }} expanded={expanded} ref={answerRef}>
          {documentToReactComponents(answer.json)}
        </Answer>
      )}
    </FAQItemElement>
  );
};

const FAQModule = ({
  intunioModuleTypeId,
  theme,
  heading,
  faqItems,
}: Props) => (
  <ModulePaddingWrapper theme={theme ?? 'Charcoal Bleach'}>
    <div id={intunioModuleTypeId}>
      {heading && (
        <H2 textAlign="center">{heading}</H2>
      )}
      <FAQItems>
        {faqItems.map((item) => (
          <FAQItem key={item.sys.id} {...item} />
        ))}
      </FAQItems>
    </div>
  </ModulePaddingWrapper>
);

export default FAQModule;
