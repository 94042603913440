import { gql } from '@apollo/client';
import Image from 'next/image';
import { ComponentProps } from 'react';
import styled from 'styled-components';
import { CFButton, CFImage, WithId } from '../../lib/types';
import Link from '../components/ArrowLink';
import Button from '../components/Button';
import FeatureHighlight from '../components/FeatureHighlight';
import Navbar from '../components/Navbar';
import PopsyncInput from '../components/PopsyncInput';

export const MAIN_HERO_IMAGE_MODULE_TYPE_ID = 'main_hero_image';

type Img = WithId<CFImage>;

type Props = {
  intunioModuleTypeId: typeof MAIN_HERO_IMAGE_MODULE_TYPE_ID,
  navbarData: ComponentProps<typeof Navbar>,
  title: string,
  subtitle: string,
  popsyncSearch: boolean,
  featureHighlights: string[],
  images: [Img, Img, Img, Img],
  mobileImages: [Img, Img],
  callToActionButton?: CFButton,
  secondaryCallToActionButton?: CFButton,
}

export const mainHeroModule = gql`
fragment mainHeroModule on MainHeroImage {
  intunioModuleTypeId
  title
  subtitle
  featureHighlights
  popsyncSearch
  callToActionButton {...button}
  secondaryCallToActionButton {...button}

  imagesCollection {
    items {...imageWithId}
  }

  mobileImagesCollection {
    items {...imageWithId}
  }
}
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  max-width: 100%;
  background-color: var(--color-charcoal-v02);
  background-image: radial-gradient(circle at 1px 1px, var(--color-charcoal-v20) 1px, transparent 0);
  background-size: 30px 30px;
  background-position: center -15px;
  color: var(--color-charcoal-v100);
`;

const Hero = styled.div`
  width: 1460px;
  max-width: 100%;
  height: 600px;
  flex-grow: 1;
  text-align: center;
  margin: 0 auto;
  padding: 76px 0 160px;
  position: relative;

  /* Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.large}) {
    padding: 24px 0 180px;
  }
`;

const Title = styled.h1`
  margin: 0;
  font-size: 48px;
  line-height: 72px;
  font-weight: normal;
  margin-bottom: 12px;

  /* Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.large}) {
    font-size: 40px;
    line-height: 52px;
  }
`;

const Subtitle = styled.div`
  font-size: 18px;
  margin-top: 32px;
`;

const ButtonWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  /* Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.large}) {
    gap: 16px;
  }
`;

const Positioned = styled.div`
  position: absolute;
`;

const HeroContent = styled.div`
  position: relative;
  max-width: max(min(750px, 50vw), 600px);
  padding: 0 16px;
  margin: 0 auto;
  z-index: 2;
`;

const HeroImages = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  pointer-events: none;
  transition: opacity ${({ theme }) => theme.transitions.short};

  @media (max-width: ${({ theme }) => theme.breakpoints.small}){
    display: none;
  }
`;

const TopLeftPosition = styled(Positioned)`
  top: 40px;
  right: 75%;
  z-index: 1;
  @media (max-width: ${({ theme }) => theme.breakpoints.large}){
    display: none;
  }
`;
const TopRightPosition = styled(Positioned)`
  top: 90px;
  left: calc(80%);
  z-index: 1;
  @media (max-width: ${({ theme }) => theme.breakpoints.large}){
    display: none;
  }
`;
const BottomLeftPosition = styled(Positioned)`
  top: 450px;
  right: 55%;
  &:last-of-type {
    top: 420px;
    right: 50%;
    margin: 0 auto;
    transform: translateX(50%);
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.large}){
    top: 330px !important;
  }
`;
const BottomRightPosition = styled(Positioned)`
  top: 460px;
  left: 53%;
`;

const HeroImagesMobile = styled.div`
  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoints.small}){
    display: unset;
  }

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  pointer-events: none;
  transition: opacity ${({ theme }) => theme.transitions.short};
  width: 100vw;
  height: 100vh;
`;
const LeftPosition = styled(Positioned)`
  position: absolute;
  top: 440px;
  right: 55%;
  &:last-of-type {
    right: 50%;
    margin: 0 auto;
    transform: translateX(50%);
  }
`;

const RightPosition = styled(Positioned)`
  position: absolute;
  top: 440px;
  left: calc(70% - 80px);
  img {
    max-height: 350px;
  }
`;

const positions = [
  TopLeftPosition,
  TopRightPosition,
  BottomLeftPosition,
  BottomRightPosition,
];
const positionsMobile = [
  LeftPosition,
  RightPosition,
];

const MainHero = ({
  intunioModuleTypeId,
  navbarData, title,
  subtitle, featureHighlights,
  images, mobileImages,
  callToActionButton,
  secondaryCallToActionButton,
  popsyncSearch,
}: Props) => (
  <section className="theme-bluescape-pale">
    <Container id={intunioModuleTypeId}>
      <Navbar {...navbarData} />
      <Hero>
        <HeroContent>
          <Title>{title}</Title>
          {featureHighlights && <FeatureHighlight>{featureHighlights[0]}</FeatureHighlight>}
          <Subtitle>{subtitle}</Subtitle>
          <ButtonWrapper className="theme-bluescape-pale">
            {popsyncSearch && (
              <PopsyncInput noPadding />
            )}
            {callToActionButton && (
              <Button href={callToActionButton.url}>{callToActionButton.label}</Button>
            )}
            {(secondaryCallToActionButton && secondaryCallToActionButton.url) && (
              <Link
                title={secondaryCallToActionButton.label}
                href={secondaryCallToActionButton.url}
              />
            )}
          </ButtonWrapper>
        </HeroContent>
        <HeroImages>
          {images.map((image, index) => {
            const Position = positions[index];
            return (
              <Position key={image.sys.id}>
                <Image
                  priority
                  width={image.width}
                  height={image.height}
                  src={image.url}
                  alt={image.description || image.title}
                />
              </Position>
            );
          })}
        </HeroImages>
        <HeroImagesMobile>
          {mobileImages.map((image, index) => {
            const PositionMobile = positionsMobile[index];
            return (
              <PositionMobile key={image.sys.id}>
                <Image
                  priority
                  width={image.width}
                  height={image.height}
                  src={image.url}
                  alt={image.description || image.title}
                />
              </PositionMobile>
            );
          })}
        </HeroImagesMobile>
      </Hero>
    </Container>
  </section>
);

export default MainHero;
