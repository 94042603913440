import { gql } from '@apollo/client';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useSearchParams } from 'next/navigation';
import { Document } from '@contentful/rich-text-types';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CFButton, WithId } from '../../lib/types';
import Button from '../components/Button';
import PricingSelector from '../components/PricingSelector';
import { ContentfulAsset, getRenderOption } from '../helpers/RichTextHelpers';

export const PRICING_TABLE_MODULE_MODULE_TYPE_ID = 'pricing_table';

export const pricingTableModule = gql`
fragment pricingTableModule on PricingTableModule {
  intunioModuleTypeId
  title
  selectorText
  pricingTableColumnCollection(limit: 10) {
    items {
      sys {
        id
      }
      title
      description {
        json
      }
      price
      pricingDescription
      primaryButton {...button}
      secondaryButton {...button}
      showDivider
      featuresTitle
      features
      color
      priceMonthly
    }
  }
}`;

const CheckIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.7071 6.70711C12.0976 6.31658 12.0976 5.68342 11.7071 5.29289C11.3166 4.90237 10.6834 4.90237 10.2929 5.29289L7 8.58579L5.70711 7.29289C5.31658 6.90237 4.68342 6.90237 4.29289 7.29289C3.90237 7.68342 3.90237 8.31658 4.29289 8.70711L6.29289 10.7071C6.68342 11.0976 7.31658 11.0976 7.70711 10.7071L11.7071 6.70711Z" fill="#338EFF" />
    <path fillRule="evenodd" clipRule="evenodd" d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8Z" fill="#338EFF" />
  </svg>
);

type PricingTableColumnProps = WithId<{
  title: string
  description: {
    json: Object
    links: {
      entries: {block: ContentfulAsset[]};
      assets: {block: ContentfulAsset[]};
    };
  }
  price: string
  pricingDescription: string
  primaryButton: CFButton
  secondaryButton: CFButton
  showDivider: boolean
  featuresTitle: string
  features: string[]
  color: string;
  priceMonthly: string;
}>;

type Props = {
  intunioModuleTypeId: typeof PRICING_TABLE_MODULE_MODULE_TYPE_ID,
  title: string;
  pricingTableColumn: Array<PricingTableColumnProps>;
  selectorText: string;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-charcoal-v02);
  padding: 32px 0;

  @media (max-width: ${(props) => props.theme.breakpoints.small}) {
    padding: 32px 0 24px 0;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
`;

const TableContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  max-width: 1440px;
  flex-wrap: wrap;
  padding: 12px 64px;
  overflow: hidden;

  @media (max-width: ${(props) => props.theme.breakpoints.medium}) {
    grid-template-columns: auto auto;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.small}) {
    grid-template-columns: auto;
    row-gap: 24px;
    padding: 12px 24px;
  }
`;

const ColumnContainer = styled.div`
    max-width: 270px;
    min-width: 196px;
    background-color: white;
    border-left: 1px solid var(--color-charcoal-v10);
    border-top: 1px solid var(--color-charcoal-v10);
    border-bottom: 1px solid var(--color-charcoal-v10);
    overflow: hidden;
    box-shadow: ${(props) => props.theme.shadows.hard};

    &:first-child {
      border-radius: 16px 0 0 16px;
    }
    &:last-child {
      border-radius: 0 16px 16px 0;
      border-right: 1px solid var(--color-charcoal-v10);
    }
    
    @media (max-width: ${(props) => props.theme.breakpoints.medium}) {
     
      &:first-child {
        border-radius: 16px 0 0 0;
      } 

      &:nth-child(2) {
        border-radius: 0 16px 0 0;
        border-right: 1px solid var(--color-charcoal-v10);
      }

      &:nth-child(3) {
        border-radius: 0 0 0 16px;
      }

      &:last-child {
        border-radius: 0 0 16px 0;
      }
    }

    @media (max-width: ${(props) => props.theme.breakpoints.small}) {
      border: 1px solid var(--color-charcoal-v10);
      border-radius: 16px !important;
      max-width: 100%;
    }

`;

const ColumnContentContainer = styled.div`
  padding: 32px;
  box-sizing: border-box;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: ${(props) => props.theme.breakpoints.large}) {
    padding: 32px 16px; 
  }
`;

const NoMarginH3 = styled.h3`
  margin: 0;
  color: var(--color-charcoal-v100);

`;

const NoMarginH2 = styled.h2`
  margin: 0;
  color: var(--color-charcoal-v100);
`;

const MarginsH5 = styled.h5`
    margin: 24px 0;
`;

type TopRectangleProps = {
  color: string;
}

const RichTextContainer = styled.div`
  display: flex;
  max-height: 72px;
  min-height: 72px;
  overflow: hidden;
  margin-top: 16px;
  margin-bottom: 24px;

  @media (max-width: ${(props) => props.theme.breakpoints.large}) {
    max-height: 128px;
    min-height: 128px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.medium}) {
    max-height: 72px;
    min-height: 72px;
  }

  p {
    margin: 0 0 24px 0;
    line-height: 24px;
    color: var(--color-charcoal-v100);
  }
`;

const PricingDescriptionP = styled.p`
  font-size: 15px;
  color: var(--color-charcoal-v100);
  margin: 4px 0 24px 0;
`;

const TopRectangle = styled.div<TopRectangleProps>`
  height: 8px;
  background-color: ${(props) => (props.color ? props.color : '#CDF2E8')};
`;

const BulletsContainer = styled.div`

`;

type ButtonContainerProps = {
  showDivider: boolean;
}

const ButtonContainer = styled.div<ButtonContainerProps>`
  height: 108px;
  width: 100%;
  display: grid;
  align-items: flex-start;
  gap: 12px;
  padding-bottom: 24px;
  flex-direction: column;
  border-bottom: ${(props) => (props.showDivider ? 'solid 1px var(--color-charcoal-v10)' : 'none')};
`;

const FeatureContainer = styled.div`
  display: grid;
  grid-template-columns: 24px 1fr;
  align-items: flex-start;
  margin-bottom: 16px;
  font-weight: 400;
  line-height: 24px;
  svg {
    margin-top: 3px;
  }

`;

const SecondaryButtonContainer = styled.div`
  display: flex;
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

const SelectorText = styled.h2`
  color: var(--color-charcoal-v100);
  font-size: 21px;
  line-height: 1.2;
  font-weight: 600;
  margin: 0;
`;

const PricingTableColumn = (props: PricingTableColumnProps & { monthly: boolean }) => (
  <ColumnContainer>
    <TopRectangle color={props.color} />
    <ColumnContentContainer>
      <NoMarginH3>
        {props.title}
      </NoMarginH3>
      <RichTextContainer>
        {props.description
          ? documentToReactComponents(
                          props.description.json as Document,
                          getRenderOption(props.description),
          )
          : null}
      </RichTextContainer>
      <NoMarginH2>
        {props.monthly ? props.priceMonthly : props.price}
      </NoMarginH2>
      <PricingDescriptionP>
        {props.pricingDescription}
      </PricingDescriptionP>
      <ButtonContainer showDivider>
        <Button href={props.primaryButton.url} fullWidth>
          {props.primaryButton.label}
        </Button>
        {props.secondaryButton
          ? (
            <SecondaryButtonContainer className="theme-bluescape-strong">
              <Button href={props.secondaryButton.url} fullWidth>
                {props.secondaryButton.label}
              </Button>
            </SecondaryButtonContainer>
          )
          : null}
      </ButtonContainer>
      <BulletsContainer>
        <MarginsH5>
          {props.featuresTitle}
        </MarginsH5>
        {props.features.map((feature) => (
          <FeatureContainer key={feature}>
            <CheckIcon />

            {feature}
          </FeatureContainer>
        ))}
      </BulletsContainer>
    </ColumnContentContainer>
  </ColumnContainer>
);

const PricingTable = (props: Props) => {
  const params = useSearchParams();
  const param = params.get('interval');
  const [monthly, setMonthly] = useState(param === 'monthly');

  useEffect(() => {
    setMonthly(param === 'monthly');
  }, [param]);

  return (
    <Container className="theme-charcoal-bleach">
      <Grid>
        <Center>
          <SelectorText>
            {props.selectorText}
          </SelectorText>
          <PricingSelector shadow setShowMonthly={setMonthly} showMonthly={monthly} />
        </Center>
        <TableContainer>
          {props.pricingTableColumn.map((column) => (
            <PricingTableColumn monthly={monthly} {...column} key={column.title} />
          ))}
        </TableContainer>
      </Grid>
    </Container>
  );
};

export default PricingTable;
