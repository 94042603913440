import styled from 'styled-components';

type Props = {
  children?: React.ReactNode;
}

const FeatureHighlight = styled.div`
  background: var(--color-bluescape-v10);
  font-size: 48px;
  color: var(--color-bluescape-v100);
  border: 2px solid var(--color-bluescape-v100);
  font-weight: 700;
  display: inline-flex;
  align-items: center;
`;

type BallProps = {
  left: boolean
}

const FeatureHighlightBall = styled.div<BallProps>`
  width: 16px;
  height: 16px;
  background: white;
  border-radius: 50%;
  border: 2px solid var(--color-bluescape-v100);
  box-sizing: border-box;
  margin-left: ${(props) => (props.left ? '-9px' : '8px')};
  margin-right: ${(props) => (props.left ? '8px' : '-9px')};
`;

export default ({ children } : Props) => (
  <FeatureHighlight>
    <FeatureHighlightBall left />
    {children}
    <FeatureHighlightBall left={false} />
  </FeatureHighlight>
);
