import { gql } from '@apollo/client';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Link from '../components/Link';
import {
  fetchDepartments, JobListing, Department,
} from '../helpers/GreenhouseHelper';

export const GREENHOUSE_MODULE_MODULE_TYPE_ID = 'greenhouse_module';

const ChevronRight = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M16.2071 11.2983C16.5976 11.6888 16.5976 12.322 16.2071 12.7125L10.2071 18.7125C9.81658 19.103 9.18342 19.103 8.79289 18.7125C8.40237 18.322 8.40237 17.6888 8.79289 17.2983L14.0858 12.0054L8.79289 6.71248C8.40237 6.32195 8.40237 5.68879 8.79289 5.29826C9.18342 4.90774 9.81658 4.90774 10.2071 5.29826L16.2071 11.2983Z" fill="#0072FF" />
  </svg>
);

export const greenhouseModule = gql`
fragment greenhouseModule on GreenhouseModule {
  intunioModuleTypeId
  title
}`;

type Props = {
  intunioModuleTypeId: typeof GREENHOUSE_MODULE_MODULE_TYPE_ID,
  title: string
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: var(--color-charcoal-v100);
`;

const ContentContainer = styled.div`
  max-width: 1128px;
  padding: 68px;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.small}) {
    padding: 68px 24px;
  }
`;

const ListRowContainer = styled.div`
  height: 48px;
  display: grid;
  align-items: center;
  gap: 12px;
  grid-template-columns: auto 24px;
  font-size: 16px;
  padding: 0 12px;
  cursor: pointer;

  &:hover {
    background-color: var(--color-bluescape-v10)
  }
  @media (max-width: ${(props) => props.theme.breakpoints.small}) {
    height: 70px;
  }
`;

const ListLeftColumns = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto 180px;
  gap: 12px;


  @media (max-width: ${(props) => props.theme.breakpoints.small}) {
    grid-template-rows: auto auto;
    grid-template-columns: auto;
    gap: 4px;
  }
`;

const OverflowP = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
`;

const LocationP = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--color-charcoal-v60);
  margin: 0;
`;

const DepartmentField = styled.fieldset`
    font-size: 15px;
    
    border: 1px solid var(--color-charcoal-v20);
    border-left: none;
    border-right: none;
    border-bottom: none;
    padding: 0;
    margin-bottom: 8px;
`;

const Legend = styled.legend`
  font-size: 18px;
  font-weight: 600;
  padding-right: 8px;
  padding-bottom: 2px;
  line-height: 22px;
`;

const JobListingLink = styled.a`
  color: var(--color-charcoal-v100);

  &:hover {
    color: var(--color-charcoal-v100);
  }
`;

const ListRow = (jobListing: JobListing) => (
  <ListRowContainer>
    <ListLeftColumns>
      <OverflowP>
        {jobListing.title}
      </OverflowP>
      <LocationP>
        {jobListing.location?.name}
      </LocationP>
    </ListLeftColumns>
    <ChevronRight />
  </ListRowContainer>
);

const GreenHouse = (props: Props) => {
  const [departments, setDepartments] = useState<Array<Department>>([]);
  const getGreenHouseListings = async () => {
    const jsonDepartments = await fetchDepartments();
    setDepartments(jsonDepartments.departments);
  };

  useEffect(() => {
    getGreenHouseListings().catch(() => {
    });
  }, []);
  return (
    <Container key={props.intunioModuleTypeId}>
      <ContentContainer>
        <h2>
          {props.title}
        </h2>
        {
          departments.map((department) => {
            if (department.jobs.length === 0) {
              return null;
            }
            return (
              <div key={department.id}>
                <DepartmentField>
                  <Legend>
                    {department.name}
                  </Legend>
                </DepartmentField>
                {
              department.jobs.map((jobListing) => (
                <Link as={JobListingLink} href={`/careers/${jobListing.id}`} key={jobListing.id}>
                  <ListRow key={jobListing.id} {...jobListing} />
                </Link>
              ))
            }
              </div>
            );
          })

        }
      </ContentContainer>
    </Container>
  );
};

export default GreenHouse;
