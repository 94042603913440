import { ComponentProps } from 'react';
import MainHero, { MAIN_HERO_IMAGE_MODULE_TYPE_ID } from './modules/MainHero';
import Content, { CONTENT_MODULE_MODULE_TYPE_ID } from './modules/Content';
import SimpleQuote, { SIMPLE_QUOTE_MODULE_MODULE_TYPE_ID } from './modules/SimpleQuote';
import Cards, { CARDS_MODULE_MODULE_TYPE_ID } from './modules/Cards';
import SubpageHeader, { SUBPAGE_HEADER_MODULE_TYPE_ID } from './modules/SubpageHeader';
import Heading, { HEADING_MODULE_MODULE_TYPE_ID } from './modules/Heading';
import CustomerLogos, { CUSTOMER_LOGOS_MODULE_MODULE_TYPE_ID } from './modules/CustomerLogos';
import HubspotForm, { HUBSPOT_FORM_MODULE_TYPE_ID } from './modules/HubspotForm';
import LargeQuote, { LARGE_QUOTE_MODULE_MODULE_TYPE_ID } from './modules/LargeQuote';
import PageBottomCTA, { PAGE_BOTTOM_CTA_MODULE_TYPE_ID } from './modules/PageBottomCTA';
import LatestUpdates, { LATEST_UPDATES_MODULE_MODULE_TYPE_ID } from './modules/LatestUpdates';
import FAQ, { FAQ_MODULE_TYPE_ID } from './modules/FAQ';
import Navbar from './components/Navbar';
import PageLinks, { PAGE_LINKS_MODULE_MODULE_TYPE_ID } from './modules/PageLinks';
import PricingTable, { PRICING_TABLE_MODULE_MODULE_TYPE_ID } from './modules/PricingTable';
import GreenHouse, { GREENHOUSE_MODULE_MODULE_TYPE_ID } from './modules/GreenHouse';
import ComparisonMatrix, { COMPARISON_MATRIX_MODULE_TYPE_ID } from './modules/ComparisonMatrix';
import FeatureCards, { FEATURE_CARDS_MODULE_MODULE_TYPE_ID } from './modules/FeatureCards';
import { CFImage, Module } from '../lib/types';
import { AnnouncementBannerProps } from './components/AnnouncementBanner';

type Props = {
  title: string,
  metaDescription: string,
  slug: string,
  modules: Array<Module>,
  ogImage?: CFImage,
  navbarData: ComponentProps<typeof Navbar>
  announcementBanner: AnnouncementBannerProps,
}

export const renderModules = (
  modules: Array<Module>,
  navbarData: ComponentProps<typeof Navbar>,
) => {
  let hasPlacedNavbar = false;
  let newNavbarData = { ...navbarData };
  return (
    modules.map((module: Module | null, index) => {
      const message = JSON.stringify(module);
      if (!module) {
        return null;
      }
      if (hasPlacedNavbar) {
        newNavbarData = { ...navbarData, announcementBanner: undefined };
      }
      switch (module.intunioModuleTypeId) {
        case MAIN_HERO_IMAGE_MODULE_TYPE_ID:
          hasPlacedNavbar = true;
          return <MainHero key={message} {...module} navbarData={newNavbarData} />;
        case CONTENT_MODULE_MODULE_TYPE_ID:
          return <Content key={message} {...module} />;
        case HEADING_MODULE_MODULE_TYPE_ID:
          return <Heading key={message} {...module} />;
        case CUSTOMER_LOGOS_MODULE_MODULE_TYPE_ID:
          return <CustomerLogos key={message} {...module} />;
        case SIMPLE_QUOTE_MODULE_MODULE_TYPE_ID:
          return <SimpleQuote key={message} {...module} />;
        case LARGE_QUOTE_MODULE_MODULE_TYPE_ID:
          return <LargeQuote key={message} {...module} />;
        case HUBSPOT_FORM_MODULE_TYPE_ID:
          hasPlacedNavbar = true;
          return <HubspotForm key={message} {...module} navbarData={newNavbarData} />;
        case FAQ_MODULE_TYPE_ID:
          return <FAQ key={message} {...module} />;
        case LATEST_UPDATES_MODULE_MODULE_TYPE_ID:
          return <LatestUpdates key={message} {...module} />;
        case SUBPAGE_HEADER_MODULE_TYPE_ID:
          hasPlacedNavbar = true;
          return <SubpageHeader key={message} {...module} navbarData={newNavbarData} />;
        case CARDS_MODULE_MODULE_TYPE_ID:
          return <Cards key={message} {...module} />;
        case PAGE_BOTTOM_CTA_MODULE_TYPE_ID:
          return <PageBottomCTA key={message} {...module} />;
        case PAGE_LINKS_MODULE_MODULE_TYPE_ID:
          return <PageLinks key={message} {...module} />;
        case PRICING_TABLE_MODULE_MODULE_TYPE_ID:
          return <PricingTable key={message} {...module} />;
        case GREENHOUSE_MODULE_MODULE_TYPE_ID:
          return <GreenHouse key={message} {...module} />;
        case COMPARISON_MATRIX_MODULE_TYPE_ID:
          return <ComparisonMatrix key={message} {...module} />;
        case FEATURE_CARDS_MODULE_MODULE_TYPE_ID:
          return <FeatureCards key={message} {...module} />;
        default:
          return (
          // eslint-disable-next-line react/no-array-index-key
            <div key={index}>
              <pre>{message}</pre>
            </div>
          );
      }
    })
  );
};

const Page = ({ modules, navbarData, announcementBanner }: Props) => {
  const newNavbarData = { ...navbarData };
  if (announcementBanner) {
    newNavbarData.announcementBanner = announcementBanner;
  }
  return (
    <div>
      {renderModules(modules, newNavbarData)}
    </div>
  );
};

export default Page;
