import styled from 'styled-components';
import { gql } from '@apollo/client';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';
import { AutoButton } from '../components/Button';
import ArrowLink from '../components/ArrowLink';
import { CFLink } from '../../lib/types';
import { AutoLink } from '../components/Link';

export const PAGE_BOTTOM_CTA_MODULE_TYPE_ID = 'page_bottom_cta';

type Props = {
  intunioModuleTypeId: typeof PAGE_BOTTOM_CTA_MODULE_TYPE_ID,
  content?: {
    json: Document
  },
  button?: CFLink,
  link?: CFLink
}

export const pageBottomCtaModule = gql`
fragment pageBottomCtaModule on PageBottomCtaModule {
  intunioModuleTypeId
  content {
    json
  }
  button {...link}
  link {...link}
}
`;

const Background = styled.section`
  background-color: #DCEBFF;
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
`;

const Graphic = styled.img`
  width: 100%;
  min-width: ${(props) => props.theme.breakpoints.medium};
  display: block;
`;

const Container = styled.div`
  padding: 50px 24px 88px 24px;
  text-align: center;
  flex-direction: column;
  max-width: 610px;
  margin: 0 auto;
  display: grid;
  gap: 20px; // 12px paragraph margin + 20px = 32px
`;

const Text = styled.div`
  display: grid;
  p {
    margin: 12px 0;
  }
  h2 {
    font-size: 40px;
    margin: 0;
    color: var(--color-bluescape-v80);
    @media (max-width: ${(props) => props.theme.breakpoints.large}) {
      font-size: 34px;
    }
    b {
      font-weight: 700;
    }
  }
  font-size: 18px;
  @media (max-width: ${(props) => props.theme.breakpoints.large}) {
    font-size: 16px;
  }
`;

const Actions = styled.div`
  display: grid;
  gap: 24px;
  justify-content: center;
`;

const LinkWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Illustration = styled.div`
  width: 100%;
  background-image: url('/images/bluescape_click.svg');
  background-repeat: no-repeat;
  background-position: 35% 40%;
  @media (max-width: ${(props) => props.theme.breakpoints.medium}) {
    background: none;
  }
`;

export default ({
  intunioModuleTypeId,
  content,
  link,
  button,
} : Props) => (
  <Background id={intunioModuleTypeId} className="theme-bluescape-pale">
    <Graphic src="/images/waves.svg" alt="Waves" />
    <Illustration>
      <Container>
        <Text>
          {content && (
            documentToReactComponents(content.json)
          )}
        </Text>
        <Actions>
          {button && <AutoButton {...button} />}
          {link && (
            <LinkWrapper>
              <AutoLink as={ArrowLink} {...link} />
            </LinkWrapper>
          )}
        </Actions>
      </Container>
    </Illustration>
  </Background>
);
