/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { gql } from '@apollo/client';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, Document } from '@contentful/rich-text-types';
import { ComponentProps, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { isDark, Theme } from '../../styles/themes';
import Button from '../components/Button';
import ModulePaddingWrapper from '../components/ModulePaddingWrapper';
import Navbar from '../components/Navbar';

export const HUBSPOT_FORM_MODULE_TYPE_ID = 'hubspot_form';

interface HubSpotScript extends HTMLScriptElement {
  forms?: {
    create: (arg: { portalId: string, formId: string, target: string }) => HTMLElement
  }
}

declare const hbspt: HubSpotScript;

type Props = {
  intunioModuleTypeId: typeof HUBSPOT_FORM_MODULE_TYPE_ID,
  formId: string
  theme: Theme
  includeNavbar: boolean
  content: {
    json: Object
    links: {
      // TODO: This should be statically typed
      entries: any; assets: any;
    }
  }
  singleColumn: boolean;
  navbarData: ComponentProps<typeof Navbar>,
}

export const hubspotFormModule = gql`
fragment hubspotFormModule on HubspotFormModule {
  intunioModuleTypeId
  formId
  theme
  includeNavbar
  content {
    json
    links {
      entries {
        block {
          sys { id }
          ...button
        }
      }
      assets {
        block {
          size
          url
          title
          sys { id }
        }
      }
    }
  }
  singleColumn
}`;

type ContainerProps = {
  includeNavbar: boolean
}

const Container = styled.div<ContainerProps>`
  justify-content: center;
  background-color: var(--theme-background);
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  grid-template-columns: 1fr;
  flex-direction: column;
  gap: 32px;
  margin: 0 auto;
  width: 100%;
  padding: 64px 0;

  &, * {
    box-sizing: border-box;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.small}) {
    gap: 24px;
  }

  fieldset {
    max-width: 100% !important;

  }

  fieldset {
    div:last-child {
      div:last-child {
        margin-right: 0px // No margins on the last
      }
    }
  }

  label {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 4px;
    display: inline-block;
    color: var(--color-charcoal-v50);
  }

  ul {
    margin: 4px;
    list-style-type: none;
    margin-block: 0;
    padding-inline: 0;
    margin-bottom: 0;
    label {
      font-size: 12px;
    }
  }

  textarea {
    font-family: inherit;
    font-size: 18px !important;
    border: 1px solid #D3D4D5;
    border-radius: 8px !important;
    width: 100% !important;
    min-width: 100% !important;
    box-sizing: border-box !important;
    padding: 8px 12px !important;
    font-family: inherit;
    color: var(--color-charcoal-v100);
    resize: none;
  }

  .hs-button {
    background-color: var(--color-bluescape-v100) !important;
    color: #fff;
    width: 100% !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    border: none !important;
    background-image: none !important;
    box-sizing: border-box !important;
  }

  .actions {
    margin: 0 !important;
    padding: 0 !important;
  }
  

  .hs-form-field {
    margin: 8px 0 8px 0;
  }

  .input {
    margin-right: 0 !important;
    width: 100% !important;
    display: flex !important;
  }

  input, select {
    font-family: inherit;
    height: 40px !important;
    font-size: 18px !important;
    border: 1px solid #D3D4D5;
    border-radius: 8px !important;
    width: 100% !important;
    box-sizing: border-box !important;
    padding: 0px 12px !important;
    padding-right: 8px;
    color: var(--color-charcoal-v100);

  }

  select {
    appearance: none;
    background-image: url('/images/chevron_down.svg');
    background-repeat: no-repeat, repeat;
    background-position: right 8px top 50%, 0 0;
    background-size: 20px auto, 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 26px;
  }
`;

const HubSpotContainer = styled.div`
display: flex;
flex-direction: column;

padding: 32px;
gap: 24px;
font-family: inherit;
background: #FFFFFF;
border: var(--color-bluescape-v20);
box-shadow: ${(props) => props.theme.shadows.hard};
border-radius: 16px;

  fieldset {
    & > div {
      width: 100% !important;
      float: none !important;

      div:last-child {
        margin-right: 0 !important;
      }


    }
  }
  .hs-form-field {
  }
`;

type StyleProps = {
  singleColumn: boolean;
}

const Grid = styled.div<StyleProps>`
  display: grid;
  grid-template-columns: repeat(${(props) => (props.singleColumn ? 1 : 2)}, 1fr);
  gap: 32px;
  max-width: ${(props) => (props.singleColumn ? '512px' : props.theme.layout.maxWidth)};
  margin: 0 auto;
  align-items: ${(props) => (props.singleColumn ? 'center' : 'flex-start')};

  > * {
    margin: 0 64px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.large}) {
    > * {
      margin: 0 24px;
    }
  }


  @media (max-width: ${(props) => props.theme.breakpoints.medium}) {
    grid-template-columns: 1fr;
    > * {
      margin: 0;
    }
  }
`;

const NavbarContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

const RichText = styled.div<StyleProps>`
  color: var(--theme-text-color);
  text-align: ${(props) => (props.singleColumn ? 'center' : 'left')};
  

  a:not(.button), .link:not(.button) {
    color: var(--theme-link-color);
    text-decoration: none;
    font-weight: 600;
  }

  .button {
    display: inline-block;
  }

  ul {
    list-style: none;
    display: grid;
    gap: 12px;
    margin: 0;
    padding: 0 0 0 32px;
  }
  li {
    display: flex;
    p {
      margin: 0;
    }
  }
  li::before {
    content: "»";
    color: var(--theme-detail-color);
    display: inline-block;
    width: 1em;
    margin-left: -1em
  }
  h1 {
    margin-top: 0;
  }
  h4 {
    font-weight: 400;
    margin-top: 32px;
    margin-bottom: 8px;
  }
  h5 {
    margin: 12px 0;
  }

  p {
    margin-bottom: 12px;
    margin-top: 0;
  }

  img {
    margin-top: 32px;
  }

  i {
      color: var(--theme-emphasized-color);
      font-style: normal;
      font-family: 'marydale';
      font-weight: bold;
      font-size: 1.2em;
    }
`;

type ContentfulAsset = {
  sys: {
    id: string
  }
  label?: string
  url: string
  title?: string
}

const getRenderOption = (content: {
  links: {
    entries: { block: Array<ContentfulAsset> };
    assets: { block: Array<ContentfulAsset> };
  };
}) => ({
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
      const entry = content?.links.entries.block.find(
        (contentEntry: ContentfulAsset) => contentEntry.sys.id === node.data.target.sys.id,
      );

      if (entry) {
        return (
          <Button
            href={entry.url}
          >
            {entry.label}
          </Button>
        );
      }
      return null;
    },
    [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
      const asset = content?.links.assets.block.find(
        (contentAsset: ContentfulAsset) => contentAsset.sys.id === node.data.target.sys.id,
      ) as { title: string, url: string };

      if (asset) {
        return (
          <img
            alt={asset.title}
            src={asset.url}
          />
        );
      }
      return null;
    },
  },

});

const createHubspotForm = (formId: string) => {
  hbspt.forms?.create({
    portalId: '2761879',
    formId,
    target: `#hubspotForm-${formId}`,
  });
};

const HubspotForm = ({
  formId, content, navbarData, theme, includeNavbar, singleColumn,
}: Props) => {
  const hbsptRef = useRef<HubSpotScript>();
  useEffect(() => {
    if (!document.getElementById('hbsptRef')) {
      hbsptRef.current = document.createElement('script');
      hbsptRef.current.id = 'hbsptRef';
      hbsptRef.current.type = 'text/javascript';
      hbsptRef.current.src = 'https://js.hsforms.net/forms/v2.js';
      hbsptRef.current.async = true;
      document.body.appendChild(hbsptRef.current);
      hbsptRef.current.onload = () => {
        if (hbsptRef.current) {
          createHubspotForm(formId);
        }
      };
    } else {
      hbsptRef.current = document.getElementById('hbspt') as HubSpotScript;
      createHubspotForm(formId);
    }
  }, [formId]);

  return (
    <ModulePaddingWrapper
      theme={theme}
    >
      {includeNavbar
        ? (
          <NavbarContainer>
            <Navbar {...navbarData} darkTheme={isDark(theme)} />
          </NavbarContainer>
        )
        : null}
      <Container includeNavbar>
        <Grid singleColumn={singleColumn ? true : !content}>
          {content
            ? (
              <article>
                <RichText singleColumn={singleColumn}>
                  {documentToReactComponents(
                    content.json as Document,
                    getRenderOption(content),
                  )}
                </RichText>
              </article>
            )
            : null}

          <HubSpotContainer>
            <div id={`hubspotForm-${formId}`} />
          </HubSpotContainer>
        </Grid>
      </Container>
    </ModulePaddingWrapper>
  );
};

export default HubspotForm;
