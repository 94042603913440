import { gql } from '@apollo/client';
import { useSearchParams } from 'next/navigation';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';
import { SVGProps, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  CFLink,
  WithId, WithTypename,
  innerAnyLinkQuery,
} from '../../lib/types';
import { Theme } from '../../styles/themes';
import Button from '../components/Button';
import Chevron, { ChevronWrapper } from '../components/Chevron';
import { AutoLink } from '../components/Link';
import ModulePaddingWrapper from '../components/ModulePaddingWrapper';
import PricingSelector from '../components/PricingSelector';
import { RichText } from '../helpers/RichTextHelpers';

export const COMPARISON_MATRIX_MODULE_TYPE_ID = 'comparison_matrix';

export interface ComparisonMatrixHeaderRow {
  rowName: string;
  __typename: 'ComparisonHeaderRow';
}

export interface ComparisonMatrixTextRow {
  rowName: string;
  columnText: [string, string, string, string];
  tooltip: {
    json: Document;
  };
  __typename: 'ComparisonMatrixTextRow';
}

export interface ComparisonMatrixCheckmarkRow {
  rowName: string;
  /** ["Column 3", "Column 4"] when column 3 and 4 is checked */
  columnsChecked: string[];
  tooltip?: {
    json: Document;
  };
  __typename: 'ComparisonMatrixCheckmarkRow';
}

export type ComparisonMatrixRow = (
  ComparisonMatrixTextRow | ComparisonMatrixHeaderRow | ComparisonMatrixCheckmarkRow
);

export interface ComparisonMatrixProps {
  title: string;
  theme: Theme;
  intunioModuleTypeId: typeof COMPARISON_MATRIX_MODULE_TYPE_ID;
  columnHeaderTitle: string;
  columnHeaderNames: string[];
  columnHeaderPrices: string[];
  columnHeaderPricesMonthly: string[];
  columnHeaderActions: WithTypename<CFLink>[];
  rows: WithId<WithTypename<ComparisonMatrixRow>>[];
}

export const comparisonMatrixModule = gql`
fragment comparisonMatrixModule on ComparisonMatrixModule {
  sys {
    id
  }

  title
  theme
  intunioModuleTypeId
  columnHeaderTitle
  columnHeaderNames
  columnHeaderPrices
  columnHeaderPricesMonthly
  columnHeaderActionsCollection {
    items {
      ... on Entry {
        sys {
          id
        }
        __typename
      }

      ${innerAnyLinkQuery}
    }
  }
  rowsCollection(limit: 80) {
    items {
      ... on Entry {
        sys {
          id
        }
        __typename
      }

      ... on ComparisonHeaderRow {
        rowName
      }
      
      ... on ComparisonMatrixCheckmarkRow {
        rowName
        columnsChecked
        tooltip { json }
      }
      
      ... on ComparisonMatrixTextRow {
        rowName
        columnText
        tooltip { json }
      }
    }
  }
}`;

const Checkmark = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="var(--color-bluescape-v80)"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.943 9.669a1 1 0 1 0-1.486-1.338l-4.695 5.217-2.255-2.255a1 1 0 0 0-1.414 1.414l3 3a1 1 0 0 0 1.45-.038l5.4-6Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.8 12c0 5.965-4.835 10.8-10.8 10.8-5.965 0-10.8-4.835-10.8-10.8C1.2 6.035 6.035 1.2 12 1.2c5.965 0 10.8 4.835 10.8 10.8Zm-2 0a8.8 8.8 0 1 1-17.6 0 8.8 8.8 0 0 1 17.6 0Z"
    />
  </svg>
);

const Dash = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={14}
    height={2}
    fill="var(--color-charcoal-v40)"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 1a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1Z"
    />
  </svg>
);

const DataRow = styled.tr`
  padding: 16px;
  font-size: 18px;
  gap: 16px;
  border-radius: 4px;
`;

const DataCell = styled.td`
  vertical-align: center;
  text-align: center;
  padding: 16px;
`;

const Matrix = styled.table`
  border-spacing: 0;
  border-collapse: separate;
  tbody {
    tr:nth-child(2n) td {
      :first-child {
        border-radius: 4px 0px 0px 4px;
      }
      
      :last-child {
        border-radius: 0px 4px 4px 0px;
      }

      background-color: var(--color-charcoal-v02);
    }
  }
`;

const Tooltip = styled.div`
  display: none;
  position: absolute;
  font-size: 15px;
  color: var(--color-charcoal-v100);
  left: 100%;
  background-color: white;
  white-space: pre-line;
  padding: 16px;
  width: max-content;
  max-width: 256px;
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid var(--theme-border-color);
  border-radius: 8px;
  box-shadow: ${(props) => props.theme.shadows.hard};
  cursor: auto;

  p {
    margin-bottom: 12px;
  }

  p:last-child {
    margin-bottom: 0;
  }
`;

const MatrixContainerContainer = styled.div<{ expanded: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  background-color: white;
  border-radius: 16px;
  box-shadow: ${(props) => props.theme.shadows.hard};
  border: 1px solid var(--theme-border-color);
  
  ${({ expanded }) => expanded && css`
    min-width: 900px;
  `}

  color: var(--color-charcoal-v100);
  gap: 24px;
`;

const DataHeadingHeader = styled.td`
  padding: 16px;
`;

const DataHeadingRow = styled.tr`
  font-weight: 600;
  font-size: 21px;
`;

const DataTitleContent = styled.span<{hasTooltip?: boolean}>`
    ${({ hasTooltip }) => hasTooltip && css`
    position: relative;
    text-decoration: underline;
    text-decoration-style: dashed;
    text-decoration-color: var(--theme-border-color-hover);
    text-underline-offset: 5px;
    /* cursor: default; */
    padding-right: 8px;

    &:hover ${Tooltip} {
      display: unset;
    }
  `}
`;

const DataTitle = styled.td`
  padding: 16px;
  text-align: start;
  font-weight: unset;
  vertical-align: center;
`;

const MatrixHeading = styled.h2`
  text-align: center;
  margin-bottom: 24px;
`;

const Headers = styled.tr`
  font-size: 18px;

  th {
    padding: 16px;
    border-bottom: 1px solid var(--color-charcoal-v10);
    position: sticky;
    z-index: 1;

    &:not(:first-child) {
      vertical-align: top;
    }
    top: 0;
    background-color: white;
  }

  a, .link {
    font-weight: 600;
  }
`;

const HeaderTitle = styled.div`
  align-items: center;
  font-weight: 600;
  color: var(--bluescape-dark);
`;

const HeaderPrice = styled.div`
  color: var(--color-charcoal-v50);
`;

const HeaderWrapper = styled.th`
  text-align: center;
  font-weight: unset;
`;
const ColumnHeader = ({ name, action, price }: { name: string; action: ComparisonMatrixProps['columnHeaderActions'][0], price: string }) => {
  const priceMatch = price.match(/\$\d+/);
  const newPrice = priceMatch ? (
    <>
      {price.slice(0, priceMatch.index)}
      <strong>{priceMatch[0]}</strong>
      {price.slice(priceMatch[0].length)}
    </>
  ) : price;
  return (
    <HeaderWrapper>
      <HeaderTitle>{name}</HeaderTitle>
      <HeaderPrice>{newPrice}</HeaderPrice>
      {action.__typename === 'AnyLink' ? (
        <AutoLink {...action} />
      ) : <div />}
    </HeaderWrapper>
  );
};

const MatrixContainer = styled.div<{ expanded: boolean }>`
  position: relative;

  ${({ expanded }) => !expanded && css`
    max-height: 416px;
    overflow: hidden;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 160px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
      display: none;
    }
  `}
`;

const Header = ({
  names, actions, title, prices,
}: { names: ComparisonMatrixProps['columnHeaderNames']; actions: ComparisonMatrixProps['columnHeaderActions']; title: string; prices: ComparisonMatrixProps['columnHeaderPrices'] }) => {
  const headers = names.map((name, i) => (
    // eslint-disable-next-line react/no-array-index-key
    <ColumnHeader price={prices[i]} name={name} action={actions[i]} key={i} />
  ));

  return (
    <thead>
      <Headers>
        <DataTitle as="th">{title}</DataTitle>
        {headers}
      </Headers>
    </thead>
  );
};
const ComparisonMatrix = ({
  title,
  theme,
  columnHeaderTitle,
  columnHeaderNames,
  columnHeaderActions,
  columnHeaderPrices,
  columnHeaderPricesMonthly,
  rows,
}: ComparisonMatrixProps) => {
  const params = useSearchParams();
  const param = params.get('interval');
  const [expanded, setExpanded] = useState(false);
  const [showMonthly, setShowMonthly] = useState(param === 'monthly');
  const columnNames = [
    'Column 1',
    'Column 2',
    'Column 3',
    'Column 4',
  ];

  useEffect(() => {
    setShowMonthly(param === 'monthly');
  }, [param]);

  const newRows = rows.map((row, i) => {
    if (row.__typename === 'ComparisonHeaderRow') {
      const followingRows = rows.slice(i + 1);
      const slice = followingRows.findIndex((r) => r.__typename === 'ComparisonHeaderRow');
      return {
        rowName: row.rowName,
        rowId: row.sys.id,
        rows: followingRows.slice(0, slice === -1 ? undefined : slice),
      };
    }
    return null;
  }).filter((r) => !!r);

  const mappedRows = newRows.map((header: typeof newRows[0]) => (
    <tbody key={header?.rowId}>
      <DataHeadingRow>
        <DataHeadingHeader>{header!.rowName}</DataHeadingHeader>
      </DataHeadingRow>
      {header!.rows.map((row) => {
        if (row.__typename === 'ComparisonHeaderRow') {
          return (
            <DataHeadingRow key={row.sys.id}>
              <DataHeadingHeader>{row.rowName}</DataHeadingHeader>
            </DataHeadingRow>
          );
        } if (row.__typename === 'ComparisonMatrixTextRow') {
          return (
            <DataRow key={row.sys.id}>
              <DataTitle>
                <DataTitleContent hasTooltip={!!row.tooltip}>
                  {row.rowName}
                  {row.tooltip && (
                    <RichText as={Tooltip}>
                      {documentToReactComponents(row.tooltip.json)}
                    </RichText>
                  )}
                </DataTitleContent>
              </DataTitle>
              {/* eslint-disable-next-line react/no-array-index-key */}
              {row.columnText.map((text, k) => <DataCell key={k}>{text}</DataCell>)}
            </DataRow>
          );
        } if (row.__typename === 'ComparisonMatrixCheckmarkRow') {
          return (
            <DataRow key={row.sys.id}>
              <DataTitle>
                <DataTitleContent hasTooltip={!!row.tooltip}>
                  {row.rowName}
                  {row.tooltip && (
                    <RichText as={Tooltip}>
                      {documentToReactComponents(row.tooltip.json)}
                    </RichText>
                  )}
                </DataTitleContent>
              </DataTitle>
              {columnNames.map((name) => {
                if (row.columnsChecked.includes(name)) {
                  return <DataCell key={name}><Checkmark /></DataCell>;
                }
                return <DataCell key={name}><Dash /></DataCell>;
              })}
            </DataRow>
          );
        }
        return undefined;
      })}
    </tbody>
  )).filter((row) => !!row);

  return (
    <ModulePaddingWrapper overflowX="auto" theme={theme}>
      <MatrixContainerContainer expanded={expanded}>
        <MatrixHeading>
          {title}
        </MatrixHeading>
        <PricingSelector setShowMonthly={setShowMonthly} showMonthly={showMonthly} small />
        <MatrixContainer expanded={expanded}>
          <Matrix>
            <Header
              title={columnHeaderTitle}
              actions={columnHeaderActions}
              names={columnHeaderNames}
              prices={showMonthly ? columnHeaderPricesMonthly : columnHeaderPrices}
            />
            {mappedRows}
          </Matrix>
        </MatrixContainer>
        <Button onClick={() => setExpanded(!expanded)}>
          <ChevronWrapper>
            <Chevron open={expanded} />
            {expanded ? 'Show less' : 'Show more'}
          </ChevronWrapper>
        </Button>
      </MatrixContainerContainer>
    </ModulePaddingWrapper>
  );
};

export default ComparisonMatrix;
