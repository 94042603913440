import Head from 'next/head';
import React from 'react';
import { CFImage } from '../../lib/types';
import { Theme } from '../../styles/themes';

export interface PageMetaProps {
  title: string;
  metaDescription: string;
  slug: string;
  imageUrl?: string;
  type?: 'website' | 'article';
  theme?: Theme;
  pageBg?: CFImage | string;
  pageBgColor?: string;
  /** Query string to put at the end of canonical url, should include "?" */
  queryString?: string;
  children?: React.ReactNode;
}

export const absoluteDomain = (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ? 'www.bluescape.com' : process.env.NEXT_PUBLIC_VERCEL_URL) || 'localhost:3000';
const secure = (process.env.NODE_ENV === 'production') || !!process.env.NEXT_PUBLIC_VERCEL_URL;

const PageMeta: React.FC<PageMetaProps> = ({
  title, metaDescription, children, imageUrl, type, slug, theme, pageBg, pageBgColor, queryString,
}) => {
  const fullTitle = title.includes('Bluescape') ? title : `${title} | Bluescape`;
  const params = new URLSearchParams();
  params.append('title', title || 'Bluescape');
  params.append('theme', theme || '');
  params.append('pageBg', typeof pageBg === 'string' ? pageBg : pageBg?.url || '');
  params.append('bgColor', pageBgColor || '');
  const realImageUrl = imageUrl || `http${secure ? 's' : ''}://${absoluteDomain}/api/og?${params.toString()}`;
  return (
    <Head>
      <title>{fullTitle}</title>
      <meta property="og:title" content={fullTitle} />
      {metaDescription && (
      <>
        <meta name="description" content={metaDescription} />
        <meta property="og:description" content={metaDescription} />
        <meta name="twitter:description" content={metaDescription} />
      </>
      )}
      {/* Better to not add canonical if slug is missing/empty */}
      {slug && <link rel="canonical" href={`https://www.bluescape.com${slug}${queryString || ''}`} />}
      <meta property="og:type" content={type === 'article' ? 'article' : 'website'} />
      {realImageUrl && (
        <>
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:image" content={realImageUrl} />
          <meta property="og:image" content={realImageUrl} />
        </>
      )}
      {children}
    </Head>
  );
};

export default PageMeta;
