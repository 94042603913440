import React from 'react';
import styled from 'styled-components';

export interface ChevronProps {
    open: boolean;
}

const FlippableSvg = styled.svg<Pick<ChevronProps, 'open'>>`
    transform: rotate(${({ open }) => (open ? '180deg' : '0deg')});
    margin-right: 8px;
    transition: transform .15s;
`;

const Chevron: React.FC<ChevronProps> = ({ open }) => (
  <FlippableSvg viewBox="0 0 12 8" width={12} height={8} fill="currentColor" xmlns="http://www.w3.org/2000/svg" open={open}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.41.91a.833.833 0 0 1 1.18 0L6 5.323 10.41.91a.833.833 0 1 1 1.18 1.178l-5 5a.833.833 0 0 1-1.18 0l-5-5A.833.833 0 0 1 .41.91Z"
    />
  </FlippableSvg>
);

export const ChevronWrapper = styled.div`
    display: inline-flex;
    align-items: center;
`;

export default Chevron;
